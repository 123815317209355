const path = require('path')

const defaultLocale = 'en-US'
const locales = ['en-US', 'fil-PH', 'ja-JAP', 'ko-KR', 'ru-RU', 'th-TH', 'zh-CN']

module.exports = {
	i18n: {
		locales,
		defaultLocale
	},
	localePath: path.resolve('./public/locales'),
	reloadOnPrerender: process.env.NODE_ENV !== 'production'
}
